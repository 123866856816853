<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" :disabled="disabled_region == true" @change="(event) => updateOfficeDropdown(event)" clearable dense prepend-icon="mdi-apps"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" :disabled="disabled_office == true" @change="(event) => updateSalesmanDropdown(event)" clearable dense prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-8">
                                <v-select v-model="salesmanModel" :items="salesmanLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" :disabled="disabled_salesman == true" @change="(event) => salesmanOnchange(event)" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-3 col-4">
                                <v-select v-model="projTypeModel" :items="projTypeLists" default="" item-value="pr_jenis" item-text="pr_jenis" label="Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-6">
                                <v-text-field v-model="projNameModel" label="Project Name" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-text-field>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="projCategoryModel" :items="projCategoryLists" default="" item-value="str1" item-text="str2" label="Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="projStageModel" :items="projStageLists" default="" item-value="str1" item-text="str2" label="Stage" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Production From(Mill)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="provinceModel" :items="provinceLists" default="" item-value="province" item-text="province" label="Province" @change="(event) => updateCityDropdown(event)" clearable dense prepend-icon="mdi-google-maps"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="cityModel" :items="cityLists" default="" item-value="city" item-text="city" label="City" @change="(event) => updateSubDistrictDropdown(event)" clearable dense prepend-icon="mdi-google-maps"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="subDistrictModel" :items="subDistrictLists" default="" item-value="districts" item-text="districts" label="Sub District" @change="(event) => updateVillageDropdown(event)" clearable dense prepend-icon="mdi-google-maps"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="villageModel" :items="villageLists" default="" item-value="vilage" item-text="vilage" label="Village" clearable dense prepend-icon="mdi-google-maps"></v-select>
                            </div>
                            <div class="col-lg-6">
                                <v-text-field v-model="addressModel" label="Project Address" clearable dense prepend-icon="mdi-google-maps"></v-text-field>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog3" v-model="dateSurveyModal" :return-value.sync="dateSurveyModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateSurveyModel" label="Survey Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateSurveyModel" :show-current="true" @click:date="$refs.dialog3.save(dateSurveyModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateSurveyModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-text-field v-model="picNameModel" label="PIC Project Name" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-text-field v-model="picPhoneModel" label="PIC Project Phone" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <!-- <v-select v-model="statModel" :items="statLists" item-value="value" item-text="text" label="Stat" :disabled="disabled_stat == true" clearable dense prepend-icon="mdi-apps"></v-select> -->
                                <v-text-field v-model="projIDModel" label="Project ID" disabled dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-3">

                            </div>
                            <div class="col-lg-3">
                                <v-btn class="mt-3" color="red" block elevation="2" small rounded @click="clearData()" dark>Clear Fields<v-icon right>mdi-eraser-variant</v-icon></v-btn>
                            </div>
                            <div class="col-lg-3">
                                <v-btn class="mt-3" color="primary" block elevation="2" small rounded @click="storeData()" dark>Save Project<v-icon right>mdi-floppy</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Project List</h5>
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.stgDesc`]="{ item }">
                                <v-chip class="ma-2" color="primary" small >
                                    <span class="text-white">{{ item.stgDesc }}</span>
                                </v-chip>
                            </template>
                            <template v-slot:[`item.details`]="{ item }">
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-1" color="primary" elevation="2" small rounded @click="editData(item)" v-on="on" dark><v-icon small>mdi-lead-pencil</v-icon></v-btn>
                                        </template>
                                        <span>Edit Project</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-1" color="red" elevation="2" small rounded @click="deleteData(item)" v-on="on" dark><v-icon small>mdi-delete</v-icon></v-btn>
                                        </template>
                                        <span>Delete Project</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="item.note_stat == '' || item.note_stat == null">
                                                <v-btn class="ma-1" color="error" elevation="2" small rounded @click="showItemDetails(item)" v-on="on"><v-icon dark>mdi-note-outline</v-icon></v-btn>
                                            </div>
                                            <div v-else-if="item.note_stat == 'K'">
                                                <v-btn class="ma-1" color="warning" elevation="2" small rounded @click="showItemDetails(item)" v-on="on"><v-icon dark>mdi-note-outline</v-icon ></v-btn>
                                            </div>
                                            <div v-else-if="item.note_stat == 'S'">
                                                <v-btn class="ma-1" color="success" elevation="2" small rounded @click="showItemDetails(item)" v-on="on"><v-icon dark>mdi-note-outline</v-icon></v-btn>
                                            </div>
                                        </template>
                                        <span>Notes</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-1" color="orange" elevation="2" small rounded @click="closeProject(item)" v-on="on" dark><v-icon small>mdi-close-octagon</v-icon></v-btn>
                                        </template>
                                        <span>Close Project</span>
                                    </v-tooltip>
                                </td>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <h5 class="list-heading">PROJECT INFO :</h5>
                            <ul>
                                <li>PROJECT PIC : {{ detailProjPIC }}</li>
                                <li>PIC PHONE : {{ detailProjPhone }}</li>
                                <li>PROJ. START : {{ detailDateStart }}</li>
                                <li>PROJ. FINISH : {{ detailDateEnd }}</li>
                                <li>ADDRESS : {{ detailAddress }}</li>
                                <li>PROVINCE : {{ detailProvince }}</li>
                                <li>STATUS : {{ detailStat }}</li>
                                <li>MILL : {{ detailMill }}</li>
                            </ul>
                        </div>

                        <div class="col-lg-6">
                            <h5 class="list-heading">FEEDBACK LIST :</h5>
                            <div v-if="arrprojectNote.length != 0">
                                <ul>
                                    <li v-for="item in arrprojectNote" :key="item.seq_num">
                                        <div v-if="item.attachment_link == '' || item.attachment_link == null">
                                            {{ item.dt_modified }}({{ item.user_id }}) :  {{ item.note }}
                                        </div>
                                        <div v-else>
                                            {{ item.dt_modified }}({{ item.user_id }}) :  {{ item.note }}  <a href="#" @click="showImageModal(item.attachment_link)" class="link-warning">(show image)</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div v-else>
                                <span class="text-white">NO FEEDBACK YET.</span>
                            </div>
                        </div>
                        <hr />
                        <div class="col-lg-12">
                            <v-textarea v-model="detailProjNote" label="FEEDBACK" dense prepend-icon="mdi-note-outline" rows="2" counter outlined></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <input type="file" name="image" id="image" @change="fileChange" class="" accept="image/*" />
                        </div>
                        <div class="col-lg-12">
                            <v-btn class="mr-4" color="success" block elevation="2" large rounded @click="storeFeedback()">{{ btnAddNoteState }}<v-icon right dark>mdi-floppy</v-icon></v-btn>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <v-img :src=imageUrl ></v-img>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-md" id="closeProjectModal" tabindex="-1" aria-labelledby="closeProjectModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-6 col-6">
                                <span>Salesman : {{ salesNameCloseModel }}</span>
                            </div>
                            <div class="col-lg-6 col-6">
                                <span>Project Name : {{ projectNameCloseModel }}</span>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-select v-model="closeByModel" :items="closeByLists" default="" item-value="value" item-text="text" label="Close Project By :" clearable dense prepend-icon="mdi-apps"></v-select>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-btn class="mr-4" color="success" block elevation="2" large rounded @click="closeProjectSubmit()">SUBMIT<v-icon right dark>mdi-floppy</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Project Management',
                disabled: false,
                href: '#',
                },
                {
                text: 'Project Tracking',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            salesmanModel: '',
            salesmanLists: [],
            projTypeModel: '',
            projTypeLists: [
                {
                    pr_jenis: 'SWASTA NASIONAL',
                },
                {
                    pr_jenis: 'BUMN',
                },
                {
                    pr_jenis: 'PMA',
                }
            ],
            projCategoryModel: '',
            projCategoryLists: [],
            projNameModel: '',
            projStageModel: '',
            projStageLists: [],
            millModel: '',
            millLists: [],
            provinceModel: '',
            provinceLists: [],
            cityModel: '',
            cityLists: [],
            subDistrictModel: '',
            subDistrictLists: [],
            villageModel: '',
            villageLists: [],
            addressModel:'',
            dateSurveyModel: '',
            dateSurveyModal: false,
            picNameModel: '',
            picPhoneModel: '',
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            projIDModel: '',
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            headers: [
                { text: 'DATE', value: 'dt_modified', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'pr_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'pr_jenis', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CATEGORY', value: 'catDesc', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SURVEY DATE', value: 'survey_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LOCATION', value: 'pr_loc', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROVINCE', value: 'propinsi', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROJECT STAGE', value: 'stgDesc', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'edit', align: 'center', class: 'primary--text blue lighten-5', sortable: false },
                { text: '', value: 'details', align: 'center', class: 'primary--text blue lighten-5', sortable: false },
            ],
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            dateSurveyFormatted: '',
            dateStartFormatted: '',
            dateEndFormatted: '',
            detailProjPrId: '',
            detailProjPIC: '',
            detailProjPhone: '',
            detailDateStart: '',
            detailDateEnd: '',
            detailAddress: '',
            detailProvince: '',
            detailStat: '',
            detailMill: '',
            detailProjNote: '',
            arrprojectNote: [],
            btnAddNoteState: 'SUBMIT',
            imageUrl: '',
            imageModel: '',
            userGroup: null,
            // statModel: 'O',
            // statLists: [
            //     {
            //         "text" : "OPEN",
            //         "value" : "O"
            //     },
            //     {
            //         "text" : "ON PROCESS",
            //         "value" : "P"
            //     },
            //     {
            //         "text" : "CLOSED",
            //         "value" : "C"
            //     },
            //     {
            //         "text" : "CANCELLED",
            //         "value" : "X"
            //     },
            // ],
            // disabled_stat: true
            closeByModel: '',
            closeByLists: [
                {
                    "text" : "CLOSED",
                    "value" : "E"
                },
                {
                    "text" : "CLOSED WITH PO",
                    "value" : "P"
                },
                {
                    "text" : "CLOSED NO PO",
                    "value" : "U"
                },
            ],
            closeProjectID: '',
            projectNameCloseModel: '',
            salesNameCloseModel: ''
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{

        async initialize(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill
                this.projCategoryLists = res.data.category
                this.projStageLists = res.data.stage
                this.provinceLists = res.data.province
                this.cityLists = res.data.city
                this.subDistrictLists = res.data.subDistrict
                this.villageLists = res.data.village
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].SalesId
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.getData()
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getData(){

            this.loadingDatatable = true

            this.itemLists = []

            if (this.dateSurveyModel) {
                this.dateSurveyFormatted = new Date(this.dateSurveyModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateSurveyFormatted = ''
            }

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    projType: this.projTypeModel ? this.projTypeModel : '',
                    projName: this.projNameModel ? this.projNameModel : '',
                    projCategory: this.projCategoryModel ? this.projCategoryModel : '',
                    projStage: this.projStageModel ? this.projStageModel : '',
                    mill: this.millModel ? this.millModel : '',
                    province: this.provinceModel ? this.provinceModel : '',
                    city: this.cityModel ? this.cityModel : '',
                    subDistrict: this.subDistrictModel ? this.subDistrictModel : '',
                    village: this.villageModel ? this.villageModel : '',
                    address: this.addressModel ? this.addressModel : '',
                    dateSurvey: this.dateSurveyModel,
                    picName: this.picNameModel ? this.picNameModel : '',
                    picPhone: this.picPhoneModel ? this.picPhoneModel : '',
                    dateStart: this.dateStartFormatted,
                    dateEnd: this.dateEndFormatted,
                    projID: this.projIDModel ? this.projIDModel : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.itemLists = res.data.result
                this.loadingDatatable = false
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)
                // if(err.response.status == '401'){
                //     this.$router.push('/');
                // }

            })
        },

        async storeData(){

            if(!this.regionModel){

                Swal.fire({
                    text: 'Please select region !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.officeModel){

                Swal.fire({
                    text: 'Please select office !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.salesmanModel){

                Swal.fire({
                    text: 'Please select salesman !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.projTypeModel){

                Swal.fire({
                    text: 'Please select project type !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.projNameModel){

                Swal.fire({
                    text: 'Please fill project name !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.projCategoryModel){

                Swal.fire({
                    text: 'Please select project category !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.projStageModel){

                Swal.fire({
                    text: 'Please select project stage !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.millModel){

                Swal.fire({
                    text: 'Please select mill !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.provinceModel){

                Swal.fire({
                    text: 'Please select province !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.cityModel){

                Swal.fire({
                    text: 'Please select city !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.subDistrictModel){

                Swal.fire({
                    text: 'Please select sub district !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.villageModel){

                Swal.fire({
                    text: 'Please select village !',
                    icon: 'warning',
                })

                return false

            }

            if(!this.addressModel){

                Swal.fire({
                    text: 'Please fill address !',
                    icon: 'warning',
                })

                return false

            }

            if (this.dateSurveyModel) {
                this.dateSurveyFormatted = new Date(this.dateSurveyModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateSurveyFormatted = ''
            }

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/storeData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    projType: this.projTypeModel ? this.projTypeModel : '',
                    projName: this.projNameModel ? this.projNameModel : '',
                    projCategory: this.projCategoryModel ? this.projCategoryModel : '',
                    projStage: this.projStageModel ? this.projStageModel : '',
                    mill: this.millModel ? this.millModel : '',
                    province: this.provinceModel ? this.provinceModel : '',
                    city: this.cityModel ? this.cityModel : '',
                    subDistrict: this.subDistrictModel ? this.subDistrictModel : '',
                    village: this.villageModel ? this.villageModel : '',
                    address: this.addressModel ? this.addressModel : '',
                    dateSurvey: this.dateSurveyFormatted,
                    picName: this.picNameModel ? this.picNameModel : '',
                    picPhone: this.picPhoneModel ? this.picPhoneModel : '',
                    dateStart: this.dateStartFormatted,
                    dateEnd: this.dateEndFormatted,
                    projID: this.projIDModel ? this.projIDModel : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                Swal.fire({
                    text: 'Project Saved Successfully !',
                    icon: 'success',
                })
                
                this.regionModel = ''
                this.officeModel = ''
                this.salesmanModel = ''
                this.projTypeModel = ''
                this.projNameModel = ''
                this.projCategoryModel = ''
                this.projStageModel = ''
                this.millModel = ''
                this.provinceModel = ''
                this.cityModel = ''
                this.subDistrictModel = ''
                this.villageModel = ''
                this.addressModel = ''
                this.dateSurveyModel = ''
                this.picNameModel = ''
                this.picPhoneModel = ''
                this.dateStartModel = ''
                this.dateEndModel = ''
                this.projIDModel = ''

                this.initialize()
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async showItemDetails(id){

            $('#detailModal').modal('show')

            this.detailProjPrId = id.pr_id
            this.detailProjPIC = id.pr_pic.toUpperCase()
            this.detailProjPhone = id.phone
            this.detailDateStart = id.pr_start
            this.detailDateEnd = id.pr_end
            this.detailAddress = id.pr_loc
            this.detailProvince = id.propinsi
            this.detailStat = id.stat
            this.detailMill = id.mill_id
            this.arrprojectNote = []
            this.detailProjNote = ''

            $('#image').val('')
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getProjectNote?id=${id.pr_id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.arrprojectNote = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async editData(id){

            this.disabled_stat = false

            axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getDataById?id=${id.pr_id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.regionModel = res.data.result[0].region.trim()
                this.officeModel = res.data.result[0].office_id.trim()
                this.salesmanModel = res.data.result[0].salesid.trim()
                this.projTypeModel = res.data.result[0].pr_jenis.trim()
                this.projNameModel = res.data.result[0].pr_name.trim()
                this.projCategoryModel = res.data.result[0].str1.trim()
                this.projStageModel = res.data.result[0].stage.trim()
                this.millModel = res.data.result[0].mill_id.trim()
                this.provinceModel = res.data.result[0].propinsi.trim()
                this.cityModel = res.data.result[0].kota.trim()
                this.subDistrictModel = res.data.result[0].kecamatan.trim()
                this.villageModel = res.data.result[0].desa.trim()
                this.addressModel = res.data.result[0].pr_loc.trim()
                this.dateSurveyModel = res.data.result[0].survey_date.trim()
                this.picNameModel = res.data.result[0].pr_pic.trim()
                this.picPhoneModel = res.data.result[0].phone.trim()
                this.dateStartModel = res.data.result[0].pr_start.trim()
                this.dateEndModel = res.data.result[0].pr_end.trim()
                this.projIDModel = res.data.result[0].pr_id.trim()

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async deleteData(id){

            Swal.fire({
                title: 'Are u sure wants to delete this projet?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
            
                if (result.isConfirmed) {
                    this.itemLists = []

                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/deleteDataById?id=${id.pr_id}`, { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        Swal.fire({
                                text: 'Project deleted successfully !',
                                icon: 'success',
                        })

                        this.getData()

                    })
                    .catch(err => {

                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401'){
                            this.$router.push('/');
                        }

                    })

                }

            })

        },

        async storeFeedback(){

            this.btnAddNoteState = 'Please Wait...'

            if(this.detailProjNote == '' || this.detailProjNote == null){

                Swal.fire({
                        text: 'Please fill feedback !',
                        icon: 'warning',
                })

                this.btnAddNoteState = 'SUBMIT'
                return

            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/storeFeedback`, { 

                    pr_id: this.detailProjPrId ? this.detailProjPrId : '',
                    note: this.detailProjNote ? this.detailProjNote : '',
                    image: this.imageModel

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.btnAddNoteState = 'SUBMIT'
                $('#detailModal').modal('hide')

                Swal.fire({
                        text: 'Feedback submitted successfully !',
                        icon: 'success',
                })

                this.getData()
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
                this.getData()
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
                this.getData()
            }
        },

        salesmanOnchange(){
            this.getData()
        },

        updateCityDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getCityByprovince?province=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.cityLists = []
                    this.cityLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getCityByprovince?province=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.cityLists = []
                    this.cityLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateSubDistrictDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getSubDistrictByCity?city=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.subDistrictLists = []
                    this.subDistrictLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getSubDistrictByCity?city=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.subDistrictLists = []
                    this.subDistrictLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateVillageDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getVillageBySubDistrict?SubDistrict=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.villageLists = []
                    this.villageLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/getVillageBySubDistrict?SubDistrict=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.villageLists = []
                    this.villageLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        clearData(){

            // this.regionModel = ''
            // this.officeModel = ''
            // this.salesmanModel = ''
            this.projTypeModel = ''
            this.projNameModel = ''
            this.projCategoryModel = ''
            this.projStageModel = ''
            this.millModel = ''
            this.provinceModel = ''
            this.cityModel = ''
            this.subDistrictModel = ''
            this.villageModel = ''
            this.addressModel = ''
            this.dateSurveyModel = ''
            this.picNameModel = ''
            this.picPhoneModel = ''
            this.dateStartModel = ''
            this.dateEndModel = ''
            this.projIDModel = ''

        },

        fileChange(e) {
            let file = e.target.files[0]
            this.imageModel = ''
            if (!file.type.includes('image/')) {
                swal("Error", "Please select an image file!", "error");
                return
            }
            // if (file.size > 10000000) {
            //     swal("Failed", "File image maximum 10 MB!", "error");
            //     return
            // }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.imageModel = event.target.result
                }
                reader.readAsDataURL(file)
            } else {
                swal("Error", "Sorry, FileReader API not supported", "error");
            }
        },

        showImageModal(imgUrl){

            $('#imgModal').modal('show')
            // this.imageUrl = process.env.VUE_APP_URL + imgUrl
            this.imageUrl = imgUrl
            
        },

        closeProject(id){

            console.log(id.pr_id);

            this.closeProjectID = id.pr_id
            this.salesNameCloseModel = id.NamaSales
            this.projectNameCloseModel = id.pr_name

            $('#closeProjectModal').modal('show')

        },

        async closeProjectSubmit(){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectTracking/closeProject`, { 

                    id: this.closeProjectID,
                    stat: this.closeByModel

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                $('#closeProjectModal').modal('hide')

                Swal.fire({
                        text: 'Project closed successfully !',
                        icon: 'success',
                })

                this.getData()
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .list-heading {
        font-weight: normal;
    }

</style>